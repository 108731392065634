<template>
    <TabsSideContainer :tabs="tabs">
        <template v-slot:body>
            <div class="tab-pane fade show active" id="tab-uno" role="tabpanel">
                <Title title="PLAN ANUAL DE ADQUISICIONES ANEXO" class="mb-4"></Title>
                <LoopPdf :loop="pdf"></LoopPdf>
            </div>
        </template>
    </TabsSideContainer>
</template>
<script>
import LoopPdf from '@/components/global/LoopPdf.vue';
import Title from '@/components/global/Title.vue';
import TabsSideContainer from '@/components/templates/tabs-side-container.vue';
export default {
    name: 'PlanAdquisiciones',
    data() {
        return {
            tabs: [
                {
                    id: 'uno',
                    name: 'Archivos'
                },
            ],
            activo: false,
            pdf: [
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/1098-Ley-de-infancia.pdf',
                    titulo: '1098-Ley-de-infancia'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/Certificado-de-Cumplimiento-FURAG-II-4-1.pdf',
                    titulo: 'Certificado-de-Cumplimiento-FURAG-II-4-1'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/Certificado-de-Cumplimiento-FURAG-II-4.pdf',
                    titulo: 'Certificado-de-Cumplimiento-FURAG-II-4'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-CUARTO-TRIMESTRE-2018.pdf',
                    titulo: 'INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-CUARTO-TRIMESTRE-2018'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-PRIMER-TRIMESTRE-2018.pdf',
                    titulo: 'INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-PRIMER-TRIMESTRE-2018'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-PRIMER-TRIMESTRE-2020-1.pdf',
                    titulo: 'INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-PRIMER-TRIMESTRE-2020-1'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-SEGUNDO-TRIMESTRE-2018.pdf',
                    titulo: 'INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-SEGUNDO-TRIMESTRE-2018'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-SEGUNDO-TRIMESTRE-2020-1.pdf',
                    titulo: 'INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-SEGUNDO-TRIMESTRE-2020-1'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-TERCER-TRIMESTRE-2018.pdf',
                    titulo: 'INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-TERCER-TRIMESTRE-2018'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-TERCER-TRIMESTRE-2020.pdf',
                    titulo: 'INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-TERCER-TRIMESTRE-2020'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/INFORME-DE-CONTROL-INTERNO-CONTABLE-2019.pdf',
                    titulo: 'INFORME-DE-CONTROL-INTERNO-CONTABLE-2019'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/INFORME-DE-DERECHOS-DE-AUTOR-de-2019.pdf',
                    titulo: 'INFORME-DE-DERECHOS-DE-AUTOR-de-2019'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/Informe-de-Evaluaci%c3%b3n-de-Control-Interno-2018.pdf',
                    titulo: 'Informe-de-Evaluación-de-Control-Interno-2018'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/Informe-del-Estado-del-Sistema-de-Control-Interno-DICIEMBRE-2020.pdf',
                    titulo: 'Informe-del-Estado-del-Sistema-de-Control-Interno-DICIEMBRE-2020'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/LEY-ESTATUTARIA-1618-DE-2013.pdf',
                    titulo: 'LEY-ESTATUTARIA-1618-DE-2013'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/PLAN-ANUAL-DE-AUDITORIAS-2020.pdf',
                    titulo: 'PLAN-ANUAL-DE-AUDITORIAS-2020'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/PLAN-ATICORRUPCION-Y-ATENCION-AL-CIDADANO2021-CTS.pdf',
                    titulo: 'PLAN-ATICORRUPCION-Y-ATENCION-AL-CIDADANO2021-CTS'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/PLAN-DE-AUDITORIAS-A%c3%91O2021.pdf',
                    titulo: 'PLAN-DE-AUDITORIAS-AÑO 2021'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/PLAN_ANUAL_AUDITORIAS_2018.pdf',
                    titulo: 'PLAN_ANUAL_AUDITORIAS_2018'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/Plan_auditorias_2019.pdf',
                    titulo: 'Plan auditorias 2019'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/RESOLUCI%c3%93N-015-ADOPCION-DEL-PLAN-ANTICORRUPCION-2020.pdf',
                    titulo: 'RESOLUCiÓN-015-ADOPCION-DEL-PLAN-ANTICORRUPCION-2020'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/SEGUIMIENTO-A-LOS-DERECHOS-DE-PETICI%c3%93N-A-junio-30-DE-2020.pdf',
                    titulo: 'SEGUIMIENTO-A-LOS-DERECHOS-DE-PETICI%c3%93N-A-junio-30-DE-2020'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/7.4/SEGUIMIENTO-A-LOS-DERECHOS-DE-PETICI%c3%93N.pdf',
                    titulo: 'SEGUIMIENTO-A-LOS-DERECHOS-DE-PETICIÓN'
                },
            ]
        }
    },
    components: {
        LoopPdf,
        TabsSideContainer,
        Title
    }
}
</script>
<style>

</style>