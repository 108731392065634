<template>
    <div class="container-fluid mt-4">
        <div class="container container-seccion-entidad">
            <div :class="['row seccion-entidad align-items-start', { 'enpujar' : activo } ]">
                <div class="col-lg-4 col-6">
                    <div class="list-group position-sticky" id="list-tab" role="tablist" style="top: 5rem;">
                        <a
                            @click="verSeleccionado"
                            class="list-group-item list-group-item-action active"
                            id="list-manual-list"
                            data-toggle="list"
                            href="#list-manual"
                            role="tab"
                            aria-controls="manual">
                            Inicio
                        </a>
                    </div>
                </div>
                <div class="col-lg-8 col-6 lista-texto">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="d-block mb-3 boton-volver">
                            <button class="btn tema-principal" @click="verSeleccionado">
                                Volver
                            </button>
                        </div>
                        <!-- secciones tabs -->
                        <div class="tab-pane fade show active" id="list-manual" role="tabpanel" aria-labelledby="list-manual-list">
                            <Title title="ENTES DE CONTROL QUE VIGILAN A LA ENTIDAD Y MECANISMOS DE SUPERVISIÓN" class="mb-4"></Title>
                            <div class="row text-center">
                                <div class="col-4 mb-4" v-for="item in enlaces" :key="item">
                                    <a target="_blank" :href="item.url" class="p-3 h-100 shadow-sm bg-white d-block">
                                        <div class="d-flex flex-wrap align-items-center justify-content-center" style="height: 140px;">
                                            <img :src="require('@/assets/img/index/'+item.img)" alt="" v-bind:width="item.size">
                                        </div>
                                        <p class="m-0">{{ item.title }}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoopPdf from '@/components/global/LoopPdf.vue';
import Title from '@/components/global/Title.vue';
export default {
    name: 'controlAusteridadLinks',
    data() {
        return {
            enlaces: [
                {
                    title: 'Ministerio de transporte',
                    url: 'https://www.mintransporte.gov.co/',
                    img: 'mintranspote.png',
                    size: 200
                },
                {
                    title: 'Ministerio de transporte',
                    url: 'https://www.supertransporte.gov.co/',
                    img: 'logo-si.png',
                    size: 150
                },
                {
                    title: 'Contraloría general de la nación',
                    url: 'https://www.contraloria.gov.co/',
                    img: 'contraloria-logo.png',
                    size: 170
                },
                {
                    title: 'Contraloría del magdalena',
                    url: 'https://www.contraloriadelmagdalena.gov.co/',
                    img: 'contraloria-magdalena.png',
                    size: 170
                },
                {
                    title: 'procuraduria General de la nacion',
                    url: 'https://www.procuraduria.gov.co/portal/',
                    img: 'procuradoria.png',
                    size: 100
                }
            ]
        }
    },
    methods: {
        verSeleccionado(){
            if (screen.width <= 768) {
                this.activo = !this.activo;
            }
        }
    },
    components: {
        LoopPdf,
        Title
    }
}
</script>