<template>
    <div class="container">
        <div class="row my-5">
            <div class="col-12">
                <h1 class="h3 mb-4 color-principal">PARTICIPACIÓN EN LA FORMULACIÓN DE POLITICAS</h1>
                <p>La Central de Transporte de Santa Marta facilita a los ciudadanos los siguientes mecanismos de participación en línea:</p>
                <ul>
                    <li>Encuestas</li>
                    <li>Chat</li>
                    <li>Redes sociales</li>
                </ul>
                <h5>De Manera Presencial:</h5>
                <hr>
                <h5>MESA DE TRABAJO TEMÁTICA</h5>
                <p>Reunión con diferentes actores y grupos de interés, para la toma de decisiones frente a los temas motivo de la convocatoria, así mismo alistamiento y preparación para las temporadas vacacionales.</p>
            </div>
        </div>
    </div>
</template>
<!-- script -->
<script>
export default {
    name: 'ParticipacionFormulacion',
    data: function () {
        return {

        }
    }
}
</script>
<!-- style -->
<style scoped lang="scss">

</style>
