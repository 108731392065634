<template src="@/views/verTransparenciaAcceso/verTransparenciaAcceso-template.vue"></template>
<style src="@/views/verTransparenciaAcceso/verTransparenciaAcceso.css"></style>

<script>
import FuncionesDeberesAnexos from '@/components/ver-transparencia-acceso/FuncionesDeberesAnexos.vue';
import ControlAusteridad from '@/components/ver-transparencia-acceso/ControlAusteridad.vue';
import controlAusteridadLinks from '@/components/ver-transparencia-acceso/controlAusteridadLinks.vue';
import PlanEstrategico from '@/components/ver-transparencia-acceso/PlanEstrategico.vue';
import ParticipacionFormulacion from '@/components/ver-transparencia-acceso/ParticipacionFormulacion.vue';
import EstudiosInvestigaciones from '@/components/ver-transparencia-acceso/EstudiosInvestigaciones.vue';

export default {
    name: 'verTransparenciaAcceso',
    data: function(){
        return {

        }
    },
    components: {
        FuncionesDeberesAnexos,
        PlanEstrategico,
        ParticipacionFormulacion,
        ControlAusteridad,
        EstudiosInvestigaciones,
        controlAusteridadLinks
    }
}
</script>