<template>
    <div class="container-fluid mt-4">
        <div class="container container-seccion-entidad">
            <div :class="['row seccion-entidad align-items-start', { 'enpujar' : activo } ]">
                <div class="col-lg-4 col-6">
                    <div class="list-group position-sticky" id="list-tab" role="tablist" style="top: 5rem;">
                        <a
                            @click="verSeleccionado"
                            class="list-group-item list-group-item-action active"
                            id="list-manual-list"
                            data-toggle="list"
                            href="#list-manual"
                            role="tab"
                            aria-controls="manual">
                            Archivos
                        </a>
                    </div>
                </div>
                <div class="col-lg-8 col-6 lista-texto">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="d-block mb-3 boton-volver">
                            <button class="btn tema-principal" @click="verSeleccionado">
                                Volver
                            </button>
                        </div>
                        <!-- secciones tabs -->
                        <div class="tab-pane fade show active" id="list-manual" role="tabpanel" aria-labelledby="list-manual-list">
                            <Title title="PLAN ESTRATEGICO" class="mb-4"></Title>
                            <LoopPdf :loop="pdf"></LoopPdf>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoopPdf from '@/components/global/LoopPdf.vue';
import Title from '@/components/global/Title.vue';
export default {
    name: 'PlanEstrategico',
    data() {
        return {
            pdf: [
                {
                    url: 'https://terminaldesantamarta.com/documentos/6.2_PLAN_ESTRATEGICO/PLAN-ESTRATEGICO_2020-2023.pdf',
                    titulo: 'PLAN ESTRATEGICO 2020-2023'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/6.2_PLAN_ESTRATEGICO/PLAN-ESTRATEGICO-2016-2019.pdf',
                    titulo: 'PLAN ESTRATEGICO 2016-2019'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/6.1_POLITICAS/Plan_de_Emergencia_%20Central_de_Transporte_Santa_Marta.pdf',
                    titulo: 'PLAN DE RESPUESTA DE EMERGENCIA Y CONTINGENCIA'
                },
            ]
        }
    },
    methods: {
        verSeleccionado(){
            if (screen.width <= 768) {
                this.activo = !this.activo;
            }
        }
    },
    components: {
        LoopPdf,
        Title
    }
}
</script>
<style>

</style>