<template>
    <div class="container">
        <div class="row my-5">
            <div class="col-12">
                <h1 class="h3 mb-4 color-principal">Estudios, Investigaciones y Otras publicaciones.</h1>
                <hr>
                <div class="d-block h5">
                    <p>La Central de Transporte de Santa Marta Ltda., es Empresa Industrial y Comercial del Estado del orden Departamental, con autonomía administrativa, financiera y patrimonio  independiente, que tiene como función principal prestar un servicio público en materia de transporte, definido como el conjunto de instalaciones que funcionan como una unidad de servicios permanentes, junto a los equipos, órganos de administración, servicios a los usuarios, a las empresas de transporte y a su parque automotor, donde se concentran las empresas autorizadas o habilitadas que cubren rutas que tienen como origen, destino o tránsito el respectivo municipio. Con el fin de generar recursos para su financiamiento y rendimientos sociales que generen beneficios para la comunidad, especialmente con el incremento de la calidad de vida de la población donde presta sus servicios, en el marco de mercados regulados por el Ministerio de Transporte (decreto 1079 de 2015).</p>
                    <p>De acuerdo con lo anterior, dentro de sus funciones NO se encuentra la de realizar estudios e investigaciones, motivo por el cual no se efectúan sus publicaciones, no obstante, en el evento de realizar estudios e investigaciones, estas serán objeto de publicación.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EstudiosInvestigaciones'
}
</script>