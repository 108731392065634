<template>
    <TabsSideContainer :tabs="tabs">
        <template v-slot:body>
            <div class="tab-pane fade show active" id="tab-uno" role="tabpanel">
                <Title title="FUNCIONES DEBERES Y ANEXOS"></Title>
                <ManualOperativo></ManualOperativo>
            </div>
            <div class="tab-pane fade" id="tab-dos" role="tabpanel">
                <LoopPdf :loop="pdf"></LoopPdf>
            </div>
        </template>
    </TabsSideContainer>
</template>
<script>
import Title from '@/components/global/Title.vue';
import TabsSideContainer from '@/components/templates/tabs-side-container.vue'
import ManualOperativo from '@/components/ver-transparencia-acceso/funciones-deberes-anexos/ManualOperativo.vue';
import LoopPdf from '@/components/global/LoopPdf.vue';
export default {
    name: 'FuncionesDeberesAnexos',
    data() {
        return {
            tabs: [
                {
                    id: 'uno',
                    name: 'Manual operativo'
                },
                {
                    id: 'dos',
                    name: 'Archivos'
                },
            ],
            pdf: [
                {
                    url: 'https://terminaldesantamarta.com/documentos/3.2_MANUAL_FUNCIONES_DEBERES_TERMINAL/MANUAL-OPERATIVO-TERMINAL-DE-TRANSPORTE-SANTA-MARTA.pdf',
                    titulo: 'MANUAL OPERATIVO PARA LA CENTRAL DE TRANSPORTE DE SANTA MARTA'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/3.3_PROCESOS_Y_PROCEDIMIENTOS/Manual-de-Funciones.pdf',
                    titulo: 'MANUAL DE FUNCIONES Y COMPETENCIAS LABORALES'
                }
            ]
        }
    },
    components: {
        TabsSideContainer,
        LoopPdf,
        Title,
        ManualOperativo
    }
}
</script>
<style>

</style>